.nav-link {
  text-decoration: none;
}

.accordion,
.progress {
  width: 100%;
}

#basic-navbar-nav {
  width: auto;
  .offcanvas-body {
    > * {
      background: transparent !important;
      display: flex !important;
      max-width: 100% !important;
      min-width: 0 !important;
      width: 100%;
      height: auto !important;
    }
  }
  .navbar-nav {
    .nav-link {
      padding: 0.5rem 1rem !important;
    }
  }
}
