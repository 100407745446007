.w-auto {
	width: auto !important;
}

.w-fill {
	width: 0;
	flex-grow: 1 !important;
}

.w-100 {
	width: 100% !important;
}

.w-48px {
	width: 48px !important;
}

.max-w-500px {
	max-width: 500px !important;
}

.min-w-280px {
	min-width: 280px !important;
}

.w-72px {
	width: 72px !important;
}

.w-600px {
	width: 600px !important;
}

.w-400px {
	width: 400px !important;
}

.w-200px {
	width: 200px !important;
}

.min-w-320px {
	min-width: 320px !important;
}

.max-w-400px {
	max-width: 400px !important;
}

.w-96px {
	width: 96px !important;
}

.w-128px {
	width: 128px !important;
}

