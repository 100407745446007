body {
  background-color: var(--bs-body-bg);
  color: var(--bs-body-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

img {
  display: block;
}

table {
  border: 1px solid map-get($theme-colors, 'primary-dark');
  border-collapse: collapse;
  border-radius: 10px;
  font-size: 0.9rem;
}

thead {
  background-color: map-get($theme-colors, 'primary');
  color: map-get($theme-colors, 'text-on-primary');
}

th {
  padding: 8px;
  border: 1px solid map-get($theme-colors, 'primary-dark');
}

td {
  padding: 8px;
  border: 1px solid map-get($theme-colors, 'primary-dark');
}