.p-10px {
	padding: 10px;
}

.p-20px {
	padding: 20px;
}

.p-1rem {
	padding: 10px;
}

.px-1_5rem {
	padding-left: 1.5rem;
	padding-right:1.5rem;
}

.py-1rem {
	padding-top: 1rem;
	padding-bottom:1rem;
}

.p-24px {
	padding: 24px;
}

.p-48px {
	padding: 48px;
}

.ps-12px {
	padding-left: 12px;
}

.pe-16px {
	padding-right: 16px;
}

.py-6px {
	padding-top: 6px;
	padding-bottom: 6px;
}

.p-8px {
	padding: 8px;
}

.p-4px {
	padding: 4px;
}

.py-32px {
	padding-top: 32px;
	padding-bottom: 32px;
}

.p-32px {
	padding: 32px;
}

.py-8px {
	padding-top: 8px;
	padding-bottom: 8px;
}

